const initState = {
    dashboard: {
        active: 'profile',
        edit: false
    },
    modalUnsavedChanges: {
        active: false, 
        callback: function(){
            
        },
        cancel: false
    },
    editing: []
}

const LoginReducer = (state = initState, action) => {
    switch(action.type){
        case 'modifyMenu':
            return {...state, dashboard: action.payload}
        case 'modifyModalUnsavedChanges':
            return {...state, modalUnsavedChanges: action.payload}
        case 'modifyEditing':
            return {...state, editing: action.payload}
        default:
            return state;
    }
};

export default LoginReducer;