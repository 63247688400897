import React, { Component }  from 'react';

export const isLocalhost = () =>  {
	return window.location.hostname === "localhost" ? `http://localhost:8000/api/` : 
		window.location.href.includes('quazgar') ? '//api-staging.trumanhealth.ca/api/' : '//api.trumanhealth.com/api/'; //https://cors-anywhere.herokuapp.com/
};

export const isLocalhostBoolean = () =>  {
	return window.location.hostname === "localhost" ? true : 
		window.location.href.includes('quazgar') ? true : false; 
};

/**
 * 
 * @param {String} photoId The user's photo name to request the file
 */
export const getProfileImage = (photoId, onlyUrl=false) => {
	let urlImage = `https://medstackstoragei0nf2keuh.blob.core.windows.net/user-ids/${photoId}${process.env.REACT_APP_ENV_STORAGE_TOKEN_USER_IDS}`;

	if (onlyUrl) {
		return urlImage;	
	}

    return (<img src={urlImage} />);
}


export const clearStorage = () => {
    localStorage.clear();
}

/**
 * Get the URL parameters
 * source: https://css-tricks.com/snippets/javascript/get-url-variables/
 * @param  {String} url The URL
 * @return {Object}     The URL parameters
 */
export const getParams = function (url) {
	var params = {};
	var parser = document.createElement('a');
	parser.href = url;
	var query = parser.search.substring(1);
	var vars = query.split('&');
	for (var i = 0; i < vars.length; i++) {
		var pair = vars[i].split('=');
		params[pair[0]] = decodeURIComponent(pair[1]);
	}
	return params;
};

/**
 * 
 * @param {Number} numericDate 
 * @param {Boolean} isNumeric 
 * @return {String} 	The formatted date (June 05, 2020)
 */
export const formatDate = (numericDate, isNumeric = true) => {
	if(!numericDate) return

	let date = isNumeric ? new Date(numericDate * 1000) : new Date(`${numericDate}`);

	let day = date.getDate() + 1;
	let year = date.getFullYear();
	let month = formatMonth(date.getMonth());
	
	return `${month} ${day}, ${year}`;
}

/**
 * 
 * @param {Number} monthNumber Month number to transform as a string
 * @return {String}		Month name returned (January, February ... December)
 */
export const formatMonth = (monthNumber) => {
	if(!monthNumber) return

	switch(monthNumber){
		case 0:
			return 'January'
		case 1:
			return 'February'
		case 2:
			return 'March'
		case 3:
			return 'April'
		case 4:
			return 'May'
		case 5:
			return 'June'
		case 6:
			return 'July'
		case 7:
			return 'August'
		case 8:
			return 'September'
		case 9:
			return 'October'
		case 10:
			return 'November'
		case 11:
			return 'December'
		default:
			return ''
	}
}

export const paddingInternal = (positionValue) => {
	let dashboardElem = document.querySelector('.dashboard');
	if(dashboardElem && window.innerWidth < 500) dashboardElem.style.paddingBottom = `${positionValue}px`;
}

export const intercomPosition = (positionValue) => {
	if(!positionValue) return

	let intercomElem = document.querySelector('.intercom-lightweight-app-launcher');
	if(intercomElem) intercomElem.style.bottom = `${positionValue}px`;

	// Padding internal edit
	paddingInternal(positionValue == 20 ? 0 : positionValue);
}

export const cleanPunctuaction = (text) => {
	return text && text.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g,"");
}


export const convertNameMedication = (nameFicticioMedication) => {
	switch(nameFicticioMedication){
		case 'Blue Rocks': 
			return 'Sildenafil 50mg';
			break
		case 'Blue Rocks Plus':
			return 'Sildenafil 100mg';
			break
		case 'Yellow rocks':
			return 'Tadalafil 20mg';
			break;
		case 'Yellow rocks daily':
			return 'Tadalafil 5mg';
			break;
		case 'Aqua rocks':
			return 'Vardenafil 5mg';
			break;
		case 'Aqua rocks plus':
			return 'Vardenafil 20mg';
		break;
		default:
			return `Unknown (${nameFicticioMedication})`
			break;
	}
}

export const statusOrder = (statusId) => {
	const status = {
		2: <span className="approved">Approved</span>,
		6: <span className="refunded">Void/Refunded</span>,
		7: <span className="declined">Declined</span>,
		8: <span className="shipped">Shipped</span>,
		11: <span className="review">Needs Review</span>
	}

	return status[statusId];
}

// Create Blob file from URL 
export const blobCreationFromURL = (inputURI) => { 
	var binaryVal; 

	// mime extension extraction 
	var inputMIME = inputURI.split(',')[0].split(':')[1].split(';')[0]; 

	// Extract remaining part of URL and convert it to binary value 
	if (inputURI.split(',')[0].indexOf('base64') >= 0) 
		binaryVal = atob(inputURI.split(',')[1]); 

	// Decoding of base64 encoded string 
	else
		binaryVal = unescape(inputURI.split(',')[1]); 

	// Computation of new string in which hexadecimal 
	// escape sequences are replaced by the character  
	// it represents 

	// Store the bytes of the string to a typed array 
	var blobArray = []; 
	for (var index = 0; index < binaryVal.length; index++) { 
		blobArray.push(binaryVal.charCodeAt(index)); 
	} 

	return new Blob([blobArray], { 
		type: inputMIME 
	}); 
} 

export const dataUriToFile = (dataURI) => {
	var mime = dataURI.split(';base64,')[0].split(':')[1];
	var filename = 'dataURI-file-' + (new Date()).getTime() + '.' + mime.split('/')[1];
	var bytes = atob(dataURI.split(';base64,')[1]);
	var writer = new Uint8Array(new ArrayBuffer(bytes.length));

	for (var i=0; i < bytes.length; i++) {
		writer[i] = bytes.charCodeAt(i);
	}

	return new File([writer.buffer], filename, { type: mime });
}

export const getCardType = (number) => {
	// visa
	var re = new RegExp("^4");
	if (number.match(re) != null)
		return  <img src={process.env.PUBLIC_URL + '/icon-visa.png'} />;

	// Mastercard 
	// Updated for Mastercard 2017 BINs expansion
	if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number)) 
		return  <img src={process.env.PUBLIC_URL + '/icon-mastercard.png'} />;

	// AMEX
	re = new RegExp("^3[47]");
	if (number.match(re) != null)
		return "AMEX";

	// Discover
	re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
	if (number.match(re) != null)
		return "Discover";

	// Diners
	re = new RegExp("^36");
	if (number.match(re) != null)
		return "Diners";

	// Diners - Carte Blanche
	re = new RegExp("^30[0-5]");
	if (number.match(re) != null)
		return "Diners - Carte Blanche";

	// JCB
	re = new RegExp("^35(2[89]|[3-8][0-9])");
	if (number.match(re) != null)
		return "JCB";

	// Visa Electron
	re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
	if (number.match(re) != null)
		return "Visa Electron";

	return  <img src={process.env.PUBLIC_URL + '/undefined.png'} />;;
}

export function isObjectEmpty(obj) {
    return Object.keys(obj).length === 0;
}
