const initState = {
    prescription: {}
}

const PrescriptionReducer = (state = initState, action) => {
    switch(action.type){
        case 'modifyPrescription':
            return {...state, prescription: action.payload}
        default:
            return state;
    }
};

export default PrescriptionReducer;