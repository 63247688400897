import React, { Component } from 'react';
import './App.scss';
import mixpanel from 'mixpanel-browser';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import ReactGA from 'react-ga';

// Modules
import PublicHeader from './modules/PublicHeader';
import PublicFooter from './modules/PublicFooter';


import { modifyUser } from './actions/UserAction';
import { modifyToken } from './actions/TokenAction';

// Routes
import Routes from './routes';


import { isLocalhostBoolean } from './utils';


const Layout = (props) => {
  let { pathname } = props.location;
  
  return (
    <>
      {pathname === '/' ? <PublicHeader history={props.history}/> : null}
        <Routes {...props} />
      {pathname === '/' ? <PublicFooter /> : null}
    </>
  )
}

class App extends Component {
  constructor(props){
    super(props);

    this.verifyLogin()
  }

  verifyLogin = () => {
    let user  = window.localStorage.getItem('user');
    let token = window.localStorage.getItem('token');

    if(user && token){
      this.props.modifyToken(token);
      this.props.modifyUser(JSON.parse(user))
    }
  }

  componentDidMount(){
    mixpanel.init(isLocalhostBoolean() ? "704243498cc0901948a428b06f0543c6" : "8c010395dba8db20405c6ffc2b4c0dd2");
    
    ReactGA.initialize('UA-153576224-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }

  render(){
    return (
      <Layout {...this.props}/>
    )
  }
}

const mapStateToProps = (state) => {
  return {
      user: state.UserReducer.user,
      token: state.TokenReducer.token
  }
};


const mapDispatchToProps = dispatch => {
  return {
      modifyUser: (data) => {
          dispatch(modifyUser(data));
      },
      modifyToken: (data) => {
          dispatch(modifyToken(data));
      }
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
