const initState = {
    signin: false,
    actionUser: false
}

const LoginReducer = (state = initState, action) => {
    switch(action.type){
        case 'modifyPanel':
            return {...state, signin: action.payload}
        case 'modifyActionUser':
            return {...state, actionUser: action.payload}
        default:
            return state;
    }
};

export default LoginReducer;