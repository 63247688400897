const initState = {
    survey: {
        groupActive: {id: 1, name: 'contact'},
        questionActive: {id: 0, name: ''},
        totalQuestions: 0,
        result: {},
        sectionsFilled: []
    },
    existingSurvey: {active: false, data: []}
}

const SurveyReducer = (state = initState, action) => {
    switch(action.type){
        case 'modifySurvey':
            return {...state, survey: action.payload}
        case 'modifyExistingSurvey':
            return {...state, existingSurvey: action.payload}
        default:
            return state;
    }
};

export default SurveyReducer;