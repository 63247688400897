const initState = {
    user: undefined
}

const UserReducer = (state = initState, action) => {
    switch(action.type){
        case 'modifyUser':
            return {...state, user: action.payload}
        default:
            return state;
    }
};

export default UserReducer;