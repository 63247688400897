import { combineReducers } from 'redux';

import UserReducer from './UserReducer';
import LoginReducer from './LoginReducer';
import TokenReducer from './TokenReducer';
import DashboardReducer from './DashboardReducer';
import SurveyReducer from './SurveyReducer';
import PractitionerReducer from './PractitionerReducer';
import PrescriptionReducer from './PrescriptionReducer';

export default combineReducers({
    UserReducer,
    LoginReducer,
    TokenReducer,
    DashboardReducer,
    SurveyReducer,
    PractitionerReducer,
    PrescriptionReducer
})