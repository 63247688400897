import React, { Component } from 'react';


import { withRouter, Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram, faTwitter, faLinkedin } from "@fortawesome/free-brands-svg-icons";


import './styles.scss';

class Footer extends Component {
    constructor(props){
        super(props)
    }

    render(){
        return (
            <footer className="footer">
                <div className="wrapper">
                    <div className="footer__sections">
                        <div className="footer__sections-item footer__sections-item--info">
                        <Link to={'/'} className="logo">
                            <img src={process.env.PUBLIC_URL + '/truman-logo.png'} />
                        </Link>
                            <p>
                            We are a telehealth company committed to providing the best personalized drug prescription service to our patients - withminimal side effects. 
                            </p>
                            <div className="paragraph">
                                <a href="mailto:hello@gettruman.com?subject=I%20have%20a%20question!" className="link">
                                    hello@gettruman.com
                                </a><br/>
                                <span>647-360-8853</span><br/>
                                <p>80 Blue Jays Way, Toronto, ON</p><br/>
                            </div>
                            <span>All content © Truman Health</span>
                            <br />
                            <a href="https://www.trumanhealth.ca/general-pages/terms" target="_blank" className="white-link">Terms &amp; Conditions</a><br/>
                            <a href="https://www.trumanhealth.ca/general-pages/privacy-policy" target="_blank" className="white-link">Privacy Policy</a>
                        </div>
                        <div className="footer__sections-item footer__sections-item--company">
                            <h3>Company</h3>
                            <nav>
                                <ul>
                                    <li>
                                        <a href="https://www.trumanhealth.ca/general-pages/about-us" target="_blank">About</a>
                                    </li>
                                    <li>
                                        <a href="https://www.trumanhealth.ca/general-pages/how-it-works" target="_blank">How it works</a>
                                    </li>
                                    <li>
                                        <a href="https://www.trumanhealth.ca/general-pages/blog" target="_blank">C'est la vie</a>
                                    </li>
                                    <li>
                                        <a href="mailto:hello@gettruman.com?subject=i have a question">Contact</a>
                                    </li>
                                </ul>
                            </nav>
                            <div>
                                <p>
                                    Partner pharmacy: <br />
                                    ORIGINS Pharmacy  <br />
                                    108-3075 Hospital Gate <br/>
                                    Oakville, Ontario<br/>
                                    (905) 847-9696
                                    <br/><br/>
                                    
                                    Health professional:<br/>
                                    Navdeep Pabla, <br/>RN BSN MSN NP<br/>
                                    Windsor, Ontario<br/>5197927846
                                </p>
                            </div>
                        </div>
                        <div className="footer__sections-item footer__sections-item--social">
                            <h3>Stay connected</h3>
                            <nav>
                                <ul>
                                    <li>
                                        <a href="https://www.facebook.com/GetTruman/" target="_blank">
                                            <i><FontAwesomeIcon icon={faFacebookF} /></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/gettruman/" target="_blank">
                                            <i><FontAwesomeIcon icon={faInstagram} /></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/health_truman" target="_blank">
                                            <i><FontAwesomeIcon icon={faTwitter} /></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/company/get-truman/" target="_blank">
                                            <i><FontAwesomeIcon icon={faLinkedin} /></i>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                            <div className="html-embed-8 w-embed w-script" style={{marginTop: "60px"}}>
                                <script src="https://static.legitscript.com/seals/4658169.js">
                                </script>
                                <a href="http://legitscript.com/pharmacy/gettruman.com" target="_blank" title="Verify LegitScript Approval">
                                    <img src="https://static.legitscript.com/seals/4658169.png" alt="LegitScript approved" width="140" height="100" border="0" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer;