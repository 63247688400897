import React, { Component, lazy, Suspense } from 'react';

import { BrowserRouter, Switch, Route, Link, Redirect, withRouter } from 'react-router-dom';


import { connect } from 'react-redux';


const Page404 = lazy(() => import('./pages/Page404'));
const Home = lazy(() => import('./pages/Home'));
const PatienIntake = lazy(() => import('./pages/PatientIntake'));
const PatientIntakeED = lazy(() => import('./pages/PatientIntakeED'));
const PatientIntakeHairLoss = lazy(() => import('./pages/PatientIntakeHairLoss'));
const PatientIntakeSmokingCessation = lazy(() => import('./pages/PatientIntakeSmokingCessation'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));
const Confirmation = lazy(() => import('./pages/Confirmation'));
const UpSell = lazy(() => import('./pages/Upsell'));
const UpSellConfirmation = lazy(() => import('./pages/UpsellConfirmation'));
const Login = lazy(() => import('./pages/Login'));
const Register = lazy(() => import('./pages/Register'));
const SetPassword = lazy(() => import('./pages/SetPassword'));

/** Practitioner routes */
const LoginPractitioner = lazy(() => import('./pages/LoginPractitioner'));

// Module
const Dashboard = lazy(() => import('./modules/Dashboard'));
const DashboardPractitioner = lazy(() => import('./modules/DashboardPractitioner'));


const ProtectedRoute = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={(props) => (
            rest.auth && ((rest.rolesIDAcess && rest.userRoleId) ? rest.rolesIDAcess === rest.userRoleId : true)
                ?
                <Component {...props} /> : <Redirect to={{ pathname: '/', state: { from: props.location, auth: false } }} />
        )} />
    );
}

class Routes extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Suspense fallback={<div className="loading--main">
                <img src={process.env.PUBLIC_URL + '/truman-logo.png'} />
                Loading...
            </div>}>
                <Switch>
                    <Route exact={true} path="/" component={Home} />
                    <Route path="/resetpassword" component={ResetPassword} />
                    <Route exact={true} path="/login" component={Login} />
                    <Route path="/register" component={Register} />
                    <Route path="/erectile-dysfunction/patient-intake" component={PatientIntakeED} />
                    <Route path="/hairloss/patient-intake" component={PatientIntakeHairLoss} />
                    <Route path="/smoking-cessation/patient-intake" component={PatientIntakeSmokingCessation} />
                    <ProtectedRoute auth={this.props.token} path="/customer/password" component={SetPassword} />
                    <Route path="/confirmation" component={Confirmation} />
                    <Route path="/upsell" component={UpSell} />
                    <Route path="/upsell-confirmation" component={UpSellConfirmation} />

                    <Route path="/login/practitioner" component={LoginPractitioner} />

                    <ProtectedRoute auth={this.props.token} exact={true} path="/dashboard" component={Dashboard} />
                    <ProtectedRoute auth={this.props.token} userRoleId={this.props.user && this.props.user.role_id ? this.props.user.role_id : false} rolesIDAcess={1} path="/dashboard/practitioner" component={DashboardPractitioner} />

                    <Route component={Page404} />
                </Switch>
            </Suspense>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.UserReducer.user,
        token: state.TokenReducer.token
    }
};


export default connect(mapStateToProps)(Routes);