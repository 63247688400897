const initState = {
    token: null
}

const TokenReducer = (state = initState, action) => {
    switch(action.type){
        case 'modifyToken':
            return {...state, token: action.payload}
        default:
            return state;
    }
}

export default TokenReducer;