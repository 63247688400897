import React from 'react';
import ReactDOM from 'react-dom';

// Router
import { BrowserRouter } from 'react-router-dom';


import { createStore } from 'redux';
import { Provider } from 'react-redux';

import App from './App';

// Reducers
import reducers from './reducers';

const store = createStore(reducers);

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById('trumanapplication')
);

