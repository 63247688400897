export const modifyMenu = (payload) => {
    return {
        type: 'modifyMenu',
        payload
    }
}

export const modifyModalUnsavedChanges = (payload) => {
    return {
        type: 'modifyModalUnsavedChanges',
        payload
    }
}

export const modifyEditing = (payload) => {
    return {
        type: 'modifyEditing',
        payload
    }
}