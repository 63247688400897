export const modifyPanel = (payload) => {
    return {
        type: 'modifyPanel',
        payload
    }
}

export const modifyActionUser = (payload) => {
    return {
        type: 'modifyActionUser',
        payload
    }
}