import React, { Component } from 'react';

import { withRouter, Link } from 'react-router-dom';

import { connect } from 'react-redux';

import './styles.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faChevronDown, faBars, faTimes } from "@fortawesome/free-solid-svg-icons";


// Actions
import { modifyActionUser, modifyPanel } from '../../actions/LoginAction';
import { modifyUser } from '../../actions/UserAction';
import { modifyToken } from '../../actions/TokenAction';
import { modifyMenu } from '../../actions/DashboardAction';

class Header extends Component {
    constructor(props){
        super(props)

        this.state = {
            menuMobile: false,
            stickHeader: false
        }
    }

    logout = () => {
        this.updateStorage()
        this.cleanData()

        // Redirect to home
        window.location.href="https://www.trumanhealth.ca"
    }

    updateStorage = () => {
        window.localStorage.removeItem('user')
        window.localStorage.removeItem('token')
    }

    cleanData = () => {
        // dispatch actions
        this.props.modifyToken(null);
        this.props.modifyUser(undefined);
    }

    openMenu = (url = '', isLogin = false) => {
        let { user, actionUser } = this.props;

        if(url != '' && (user && Object.keys(user).length)){
            this.props.history.push(url)
            return
        }

        
        this.props.history.push(isLogin ? '/login' : '/register');

        if(actionUser){
            document.body.classList.remove('active');

            return
        }
        
        document.body.classList.add('active');
    }

    goDashboard = (active) => {
        this.props.modifyMenu({active});
        this.props.history.push(`/dashboard`);
    }

    stickHeader = () => {
        window.onscroll = () => {
            if(window.scrollY > 0 && this.state.stickHeader != true){
                this.setState({stickHeader: true})
            } else if(window.scrollY === 0 && this.state.stickHeader != false){
                this.setState({stickHeader: false})
            }
        }
    }

    componentDidMount(){
        this.stickHeader()
    }

    render(){
        return (
            <header className="header__wrapper">
                <div className={`header header--public ${this.state.stickHeader ? 'fixed' : ''}`}>
                    <Link to={'https://www.trumanhealth.ca'} className="logo">
                        <img src={process.env.PUBLIC_URL + '/logo.svg'} />
                    </Link>
                    <button className="open-menu visible-xs" onClick={() => this.setState({menuMobile: true})}>
                        <FontAwesomeIcon icon={faBars} />
                    </button>
                    <nav className={`menu header__nav ${this.state.menuMobile ? 'active' : ''}`}>
                        <button className="close-menu visible-xs" onClick={() => this.setState({menuMobile: false})}>
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                        <ul className="header__menu">
                            {this.props.user ? 
                                <li className="header__menu-item header__menu-item--dropdown nav-link">
                                    <a>
                                        My Account
                                        <i className="header__menu-icon">
                                            <FontAwesomeIcon icon={faChevronDown} />
                                        </i>
                                    </a>
                                    <ul className="header__submenu">
                                        <li className="header__submenu-item">
                                            <a onClick={() => this.goDashboard('prescriptions')}>
                                                Prescriptions
                                            </a>
                                        </li>
                                        <li className="header__submenu-item">
                                            <a onClick={() => this.goDashboard('profile')}>
                                                Profile
                                            </a>
                                        </li>
                                        <li className="header__submenu-item">
                                            <a onClick={() => this.goDashboard('medical')}>
                                                Medical Information
                                            </a>
                                        </li>
                                        <li className="header__submenu-item">
                                            <a onClick={() => this.goDashboard('billing')}>
                                                Billing
                                            </a>
                                        </li>
                                        <li className="header__submenu-item">
                                            <a onClick={() => this.goDashboard('shipping')}>
                                                Shipping
                                            </a>
                                        </li>
                                        <li className="header__submenu-item" onClick={() => this.logout()}>
                                            <a>
                                                Logout
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            :   
                                <li className="header__menu-item nav-link" onClick={() => this.openMenu('', true)}>
                                    Login
                                </li>
                            }

                            <li className="header__menu-item header__menu-item--dropdown nav-link">
                                <a className="btn btn-dark btn-startvisit btn-xs">
                                    Start online visit

                                    <i className="header__menu-icon">
                                        <FontAwesomeIcon icon={faChevronDown} />
                                    </i>
                                </a>
                                <ul className="header__submenu">
                                    <li className="header__submenu-item">
                                        <a onClick={() => this.props.history.push('/erectile-dysfunction/patient-intake?dis=erectile')}>
                                            Erectile dysfunction
                                        </a>
                                    </li>
                                    <li className="header__submenu-item">
                                        <a onClick={() => this.props.history.push('/hairloss/patient-intake?dis=hairloss')}>
                                            Hair loss
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </nav>
                </div>
            </header>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.UserReducer.user,
        signin: state.LoginReducer.signin
    }
};


const mapDispatchToProps = dispatch => {
    return {
        modifyPanel: (data) => {
            dispatch(modifyPanel(data));
        },
        modifyActionUser: (data) => {
            dispatch(modifyActionUser(data))
        },
        modifyUser: (data) => {
            dispatch(modifyUser(data));
        },
        modifyToken: (data) => {
            dispatch(modifyToken(data));
        },
        modifyMenu: (data) => {
            dispatch(modifyMenu(data))
        }
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(Header);
