const initState = {
    practitioner: {
        page: 'orders'
    },
    orderStatus: {title: 'Needs Review', label: 'needs', status: 'pending'},
    orders: [],
    patient: {
        info: {},
        medicationRequest: {},
        healthInfo: [],
        prescriptionHistory: {}
    }
}

const PractitionerReducer = (state = initState, action) => {
    switch(action.type){
        case 'modifyPractitioner':
            return {...state, practitioner: action.payload}
        case 'modifyOrderStatus':
            return {...state, orderStatus: action.payload}
        case 'modifyOrders':
            return {...state, orders: action.payload}
        case 'modifyPatient':
            return {...state, patient: action.payload}
        case 'modifyPatientInfo':
            return {...state, patient: {...state.patient, info: action.payload}}
        case 'modifyPatientMedicationRequest':
            return {...state, patient: {...state.patient, medicationRequest: action.payload}}
        case 'modifyPatientHealthInfo':
            console.log({...state, patient: {...state.patient, healthInfo: action.payload}})
            return {...state, patient: {...state.patient, healthInfo: action.payload}}
        case 'modifyPatientPrescriptionHistory':
            return {...state, patient: {...state.patient, prescriptionHistory: action.payload}}
        default:
            return state;
    }
}

export default PractitionerReducer;